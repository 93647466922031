import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Typography, Container, Card, CardContent, Grid, useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import stylish from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { gsap } from 'gsap';
import { motion, AnimatePresence } from "framer-motion";
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import TimerIcon from '@mui/icons-material/Timer';
import { green } from '@mui/material/colors';
import founderImage from '../assets/founder.JPG'; // Adjust the path as per your file structure
import heroImage1 from '../assets/hero.png';
import heroImage2 from '../assets/hero2.png';
import heroImage3 from '../assets/hero3.png';
import heroImage4 from '../assets/hero4.png';
import heroImage5 from '../assets/hero5.png';
import heroImage6 from '../assets/hero6.png';
import skylineImage1 from '../assets/poolskylinehero1.png';
import skylineImage2 from '../assets/poolskylinehero2.png';
import onboardingImage from '../assets/onboarding.png';
import skylineImage3 from '../assets/poolskylinehero3.png'
import premiumImage3 from '../assets/Premium5.png';
import tailoredImage1 from '../assets/Tailored1.png';
import logoImage from '../assets/Mesa de trabajo 2.png'; // Adjust the path as per your file structure
import { Parallax as ParallaxReact } from 'react-parallax';
import ScrollProgressBar from 'react-scroll-progress-bar';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';





const MissionStatementSection = styled(Box)({
  padding: '40px 0',
  textAlign: 'center',
  backgroundColor: '#f5f5f5',
  margin: '40px 0',
});

const WhatsAppButtonStyle = styled(IconButton)({
  position: 'fixed',
  bottom: '20px',
  width: '50px',
  height: '50px',
  right: '20px',
  backgroundColor: '#25D366',
  color: 'white',
  '&:hover': {
    backgroundColor: '#128C7E',
  },
  zIndex: 1000,
});



// Object to hold hero images
const heroImages = {
  image1: heroImage1,
  image2: heroImage2,
  image3: heroImage3,
  image4: heroImage4,
  image5: heroImage5,
  image6: heroImage6,
  image7: skylineImage1,
  image8: skylineImage2,
  image9: skylineImage3,
};

const CustomButton = stylish.button`  display: inline-block;
font-size: 10px;
text-transform: uppercase;
letter-spacing: 1.5px;
color: white;
background-color: #000;
border: none;
cursor: pointer;
padding: 12px 24px;
transition: all 0.3s ease;
border-radius: 25px;
box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
margin: 4px 2px;
:hover {
  background-color: #45a049;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: white;
  transform: translateY(-7px);`;

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0 },
  };

  

const Background = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  "&::before": {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.0)', 
    zIndex: 1,
  },
});

// Styled components
const Banner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  position: 'relative',
  color: '#fff',
  marginBottom: '1rem',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '80vh',
  paddingTop: '5rem',
  paddingBottom: '5rem',
  overflow: 'hidden',
  zIndex: 2,
}));



const StyledParallax = styled(ParallaxReact)({
  position: 'relative',
  height: '90vh',
  backgroundPosition: 'center 90%', // Adjust the vertical positioning here
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,)', // Darken the image
    zIndex: 1,
  },
});
// Update serviceList to reflect new services
const BulletPoint = ({ text }) => (
  <motion.li
    variants={itemVariants}
    style={{
      background: 'rgba(255, 255, 255, 0.8)',
      padding: '5px 10px',
      margin: '5px 0',
      borderRadius: '5px',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
      fontSize: '0.9rem',
      marginTop: '7px'
    }}
  >
    {text}
  </motion.li>
);

const serviceList = [
  {
    title: "Full Management Package", // Do not translate title
    features: [
      "Renovación y marketing interior", // Interior facelift, photography & marketing
      "Operaciones y mantenimiento de alquiler", // Rental operations & maintenance
      "Amenidades premium para huéspedes", // Premium guest amenities & services
      // Agrega más características en puntos
    ],
    icon: <CleanHandsIcon fontSize="large" sx={{ color: green[500] }} />,
  },
  {
    title: "Tailored Pay-As-You-Go", // Do not translate title
    features: [
      "Transición a alquiler en vivo", // Transition your new apartment to live rental
      "Limpieza de obra y diseño interior", // Construction clean-up and interior design
      "Amueblamiento total y decoración", // Total apartment furnishing and decoration
      // Agrega más características en puntos
    ],
    icon: <TimerIcon fontSize="large" sx={{ color: green[500] }} />,
  },
  // Agrega más servicios según sea necesario
];




const ServiceCard = ({ service, packageContent, contactRef }) => {
  const [detailedView, setDetailedView] = useState(false);
  const [contentIndex, setContentIndex] = useState(0);
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });
  const [startAnimation, setStartAnimation] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const handleButtonClick = () => {
    setDetailedView(prev => !prev);
    setShowButton(false); // Hide the button when detailed view is shown
  };

  const detailedViewVariants = {
    hidden: { opacity: 0, y: 20 },
    visible:
{ opacity: 1, y: 0 },
};
const handleGetInTouch = () => {
  contactRef.current?.scrollIntoView({ behavior: 'smooth' });
};



const howItWorksButtonVariants = {
hidden: { opacity: 0, x: 50 },
visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};

const currentContent = packageContent[service.title][contentIndex];
const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

const calculateTopMargin = () => {
if (isMobile) {
return service.title === "Tailored Pay-As-You-Go" ? '243px' : '207px';
} else {
return '240px';
}
};

const topMargin = calculateTopMargin();

useEffect(() => {
if (inView) {
setStartAnimation(true);
setTimeout(() => setShowButton(true), 1600);
}
}, [inView]);

useEffect(() => {
const interval = setInterval(() => {
setContentIndex(prevIndex => (prevIndex + 1) % packageContent[service.title].length);
}, 5000);
return () => clearInterval(interval);
}, [service.title, packageContent]);

return (
<div ref={ref} style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
<Card
sx={{
height: '400px',
width: '100%',
position: 'relative',
'&:hover': {
transform: 'scale(1.05)',
boxShadow: '0 5px 15px rgb(0 0 0 / 0.3)'
},
...(service.title === 'Full Management Package' && { border: '2px solid #ffd700' }),
...(service.title === 'Tailored Pay-As-You-Go' && { border: '2px solid green' }),
transition: 'transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out',
}}
>
<Box
sx={{
position: 'absolute',
top: 0,
left: 0,
right: 0,
bottom: 0,
backgroundImage: `url(${currentContent.image})`,
backgroundSize: 'cover',
backgroundPosition: 'center',
filter: detailedView ? 'blur(4px) brightness(0.4)' : 'none',
transition: 'filter 0.5s ease-in-out',
zIndex: 1,
}}
/>
<CardContent sx={{ position: 'relative', zIndex: 2 }}>
{startAnimation && !detailedView && (
<>
<Typography variant="h5" style={{ color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 3)' }}>
{currentContent.title}
</Typography>
<motion.ul
initial="hidden"
animate="visible"
variants={{
hidden: { opacity: 0 },
visible: {
opacity: 1,
transition: {
staggerChildren: 0.5,
},
},
}}
style={{ listStyleType: 'none', padding: 0, marginTop: topMargin }}
>
{service.features.map((feature, index) => (
<BulletPoint key={index} text={feature} />
))}
</motion.ul>
</>
)}
{showButton && (
<motion.div
initial="hidden"
animate="visible"
variants={howItWorksButtonVariants}
style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-160px' }}
>
<CustomButton onClick={handleButtonClick}>
Cómo Funciona →
</CustomButton>
</motion.div>
)}
{detailedView && (
<motion.div
initial="hidden"
animate="visible"
variants={detailedViewVariants}
style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', padding: '20px', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
>
{service.title === "Full Management Package" && (
<>
<Typography variant={isMobile ? "h5" : "h4"} textAlign="center" marginTop="370px">Gestión Completa</Typography>
<Typography variant={isMobile ? "subtitle1" : "h3"} textAlign="center" marginBottom={'5px'}>Tarifa del 20%</Typography>
<Typography variant={isMobile ? "body2" : "body1"} textAlign="center" marginBottom={'10px'}>Nos encargamos de todo.</Typography>
<Typography variant={isMobile ? "body1" : "body1"} textAlign="center" marginBottom={'0px'}> <b>Listado</b></Typography>

<Typography variant={isMobile ? "body2" : "body1"} textAlign="center" marginBottom={'6px'}>Fotografía profesional y creación de listado premium en Airbnb.</Typography>
<Typography variant={isMobile ? "body1" : "body1"} textAlign="center" marginBottom={'0px'}> <b>Gestión</b></Typography>

<Typography variant={isMobile ? "body2" : "body1"} textAlign="center" marginBottom={'6px'}>Reserva, comunicación con huéspedes, limpieza, gestión de llaves, check-ins y check-outs, coordinación de mantenimiento.</Typography>
<Typography variant={isMobile ? "body1" : "body1"} textAlign="center" marginBottom={'0px'}> <b>Maximización de beneficios</b></Typography>

<Typography variant={isMobile ? "body2" : "body1"} textAlign="center" marginBottom={'0px'}>Servicios premium para huéspedes y mejoras para obtener las mejores reseñas e ingresos máximos.</Typography>
</>
)}
{service.title === "Tailored Pay-As-You-Go" && (
<>
<Typography variant={isMobile ? "h5" : "h4"} textAlign="center" marginTop="370px">AceleraRenta</Typography>
<Typography variant={isMobile ? "subtitle1" : "h5"} textAlign="center" color={'#e5bd6c'}fontWeight={'normal'}marginBottom={'5px'}>Contactanos para una cotización personalizada</Typography>
<Typography variant={isMobile ? "body2" : "body1"} textAlign="center" marginBottom={'10px'}>Convertimos tu nuevo apartamento en un alquiler premium rápidamente.</Typography>
<Typography variant={isMobile ? "body1" : "body1"} textAlign="center" marginBottom={'0px'}> <b>Limpieza Profunda</b></Typography>

<Typography variant={isMobile ? "body2" : "body1"} textAlign="center" marginBottom={'6px'}>Limpieza intensiva post-construcción para eliminar suciedad y polvo fino.</Typography>
<Typography variant={isMobile ? "body1" : "body1"} textAlign="center" marginBottom={'0px'}> <b>Diseño Interior</b></Typography>

<Typography variant={isMobile ? "body2" : "body1"} textAlign="center" marginBottom={'6px'}>Trabajo de diseño interior dedicado. Proyecto enfocado en la selección de estilo y mobiliario.</Typography>
<Typography variant={isMobile ? "body1" : "body1"} textAlign="center" marginBottom={'0px'}> <b>Amueblado</b></Typography>

<Typography variant={isMobile ? "body2" : "body1"} textAlign="center" marginBottom={'5px'}>Envío y instalación de mobiliario.</Typography>

</>
)}


<motion.div
  initial="hidden"
  animate="visible"
  variants={howItWorksButtonVariants}
  style={{
    position: 'relative',
    bottom: '40%',
    right: '0%',
    transform: 'translateX(50%)',
    marginBottom: ''
  }}
>
<CustomButton onClick={handleGetInTouch}>
Contáctanos
      </CustomButton>
</motion.div>
</motion.div>


)}
</CardContent>
</Card>
</div>
);
};


const BioSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const bioVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        bounce: 0.4,
        duration: 1,
      },
    },
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'center', my: 5, mx: 'auto', maxWidth: 'md' }}>
      <Box sx={{ maxWidth: '300px', width: '100%', p: 2, order: isMobile ? 2 : 1 }}>
        <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={bioVariants}>
        <Typography variant="h4" gutterBottom component="div" sx={{ textAlign: isMobile ? 'center' : 'left' }}>Historia del Fundador</Typography>
<Typography variant="h6" gutterBottom component="div" sx={{ marginBottom: '20px', textAlign: isMobile ? 'center' : 'left' }}>Transformando espacios en oasis, con el fundador Emil Thorsplass.</Typography>
<Typography variant="body1" sx={{ textAlign: isMobile ? 'center' : 'left', marginBottom: '10px' }}>
De Noruega a Argentina, mi viaje ha sido descubrir qué realmente hace que un lugar se sienta como en casa. Gestionar alquileres temporarios y hospedarme en innumerables Airbnbs me enseñó la importancia de la atención al detalle y la hospitalidad auténtica.
</Typography>
<Typography variant="body1" sx={{ textAlign: isMobile ? 'center' : 'left', marginBottom: '10px' }}>
Al establecerme en Argentina, me enamoré de su cultura y vi la verdadera necesidad de un servicio que pudiera elevar los alquileres temporarios con esos mismos principios. Así nació Aires Bnb. No se trata solo de alquilar espacios; se trata de crear experiencias y dar a los huéspedes una conexión emocional con su hogar temporal.
</Typography>


        </motion.div>
      </Box>
      <Box sx={{ maxWidth: '300px', width: '100%', p: 2, order: isMobile ? 1 : 2 }}>
        <motion.img
          src={founderImage}
          alt="Founder"
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ type: 'spring', stiffness: 100 }}
          style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 10px 20px rgba(0,0,0,0.2)' }}
        />
        
      </Box>

    </Box>
  );
};

const BitcoinIcon = (props) => (
  <img src="https://logodownload.org/wp-content/uploads/2017/06/bitcoin-logo-7-1.png" alt="Bitcoin Icon" {...props} />
);


const packageContent = {
  "Full Management Package": [
    { title: "Gestión Completa: Renta Óptima, Ganancias Máximas", description: "Effortless booking and hosting management...", image: premiumImage3 },
    // Add more content items as needed
  ],
  "Tailored Pay-As-You-Go": [
    { title: "AceleraRenta", description: "Pay only for what you use, no hidden charges...", image: onboardingImage },
    // Add more content items as needed
  ],
  // Add more packages if necessary
};

// ContactSection component with revised styling
const ContactSection = ({ onRef }) => {
  return (
    <Box ref={onRef} sx={{
      padding: '60px 0',
      textAlign: 'center',
      backgroundColor: '#f5f5f5',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Typography fontFamily='helvetica'variant="h4" gutterBottom sx={{
        fontWeight: 'bold',
        marginBottom: '20px',
        fontSize: '2.5rem',
        color: '#00796b'
      }}>
        Contáctanos
      </Typography>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        alignItems: 'center',
        marginBottom: 16
      }}>
        <IconButton href="https://wa.me/+4741344688" target="_blank" size="large" sx={{
          color: green[500],
          '&:hover': { backgroundColor: '#00796b' }
        }}>
          <WhatsAppIcon fontSize="large" />
        </IconButton>
        <Typography variant="h6" sx={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <a href="mailto:emil@airesbnb.com" style={{
            textDecoration: 'none',
            color: 'inherit',
            fontWeight: 'bold',
          }}>
            emil@airesbnb.com
          </a>
        </Typography>
        
      </Box>
      <Typography color="black" textAlign="center" variant="body1" fontSize='8px' sx={{ mb: -7 }}>
      *No tenemos afiliación con Airbnb Inc. No ofrecemos, ni tenemos la intención de ofrecer, servicios similares.      </Typography>
    </Box>
  );
};
const notificationVariants = {
  hidden: { y: '100%', opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { type: 'spring', stiffness: 70, damping: 12 } },
  exit: { y: '100%', opacity: 0, transition: { duration: 0.3 } },
};

const NotificationBox = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white; // Changed to a lighter color
  padding: 20px; // Increased padding
  display: flex;
  flex-direction: column; // Changed to column layout
  align-items: center;
  justify-content: center;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1); // Adjusted box shadow
  border-top-left-radius: 10px; // Added rounded corners
  border-top-right-radius: 10px;
  z-index: 1000;
  max-width: 500px; // Limiting the width
  margin: 0 auto; // Centering in the viewport
`;

const StyledButton = styled(Button)`
  background-color: #00796b; // Custom color
  color: white;
  margin-top: 15px; // Spacing between text and button
  padding: 10px 30px; // Padding inside the button
  border-radius: 20px; // Rounded button
  font-weight: bold;
  &:hover {
    background-color: #005b4f;
  }
`;

const Home = () => {
  const contactRef = useRef(null);
  const [currentHeroImage, setCurrentHeroImage] = useState(heroImages.image9);
  const logoRef = useRef(null);
  const [tagline, setTagline] = useState("Tu Propiedad, Potencial Liberado");
  const [isFirstTaglineComplete, setIsFirstTaglineComplete] = useState(false);
  const [isSecondTaglineShown, setIsSecondTaglineShown] = useState(false);
  const [startTyping, setStartTyping] = useState(false); // Define the state and setter
const [showDetails, setShowDetails] = useState(false);
const { ref, inView } = useInView({ threshold: 0.1 });

useEffect(() => {
  // Show notification after 20 seconds if 'Get in Touch' section is not in view
  const timer = setTimeout(() => {
    if (!inView) {
      setShowNotification(true);
    }
  }, 20000);

  return () => clearTimeout(timer);
}, [inView]);

useEffect(() => {
  // Show notification when 'Get in Touch' section is in view
  if (inView) {
    setShowNotification(true);
  }
}, [inView]);


const handleYesClick = () => {
setShowDetails(true);
};


// New state to manage typewriter text
const [typewriterText, setTypewriterText] = useState("");
const [isTyping, setIsTyping] = useState(true);
const missionStatementRef = useRef(null);
const scrollToMissionStatement = () => {
  missionStatementRef.current?.scrollIntoView({ behavior: 'smooth' });
};

const handleGetInTouchClick = () => {
  contactRef.current?.scrollIntoView({ behavior: 'smooth' });
};

const preFilledEmailBody = encodeURIComponent(
  "Hola,\n\n" +
  "Estoy interesado/a en unirme al programa de referidos en Aires BnB.\n\n"
);

const mailToLink = `mailto:emil@airesbnb.com?subject=Realtor Referral Program Inquiry&body=${preFilledEmailBody}`;

// Motion variants for the "Learn More" button
const whatsappUrl = 'https://wa.me/+4741344688';
const buttonVariants = {
  hidden: {
    x: -50,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 50,
      damping: 20,
    },
  },
};
const TaglineContainer = styled('div')(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  color: '#fff',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
  textAlign: 'center',
  marginTop: '-160px',
  marginBottom: '0px',
  marginLeft: '15%',
  marginRight: '15%',
  

  minHeight: '190px', // Set a fixed height
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.9rem',
    marginTop: '-200px',
    marginBottom: '0px',
    marginLeft: '2%',
  marginRight: '2%',
  },
}));
// Custom typewriter function
// Custom typewriter function with adjustable speed
const typeText = (text, index = 0, speed = typingSpeed) => {
  if (index < text.length) {
    setTypewriterText(text.substring(0, index + 1));
    setTimeout(() => typeText(text, index + 1, speed), speed);
  } else {
    setIsTyping(false); // Stop typing
    if (!isFirstTaglineComplete) {
      setIsFirstTaglineComplete(true);
    }
  }
};

useEffect(() => {
  // Start typing the first tagline when the component mounts
  typeText("Tu Propiedad, Potencial Liberado");
}, []);

useEffect(() => {
  if (isFirstTaglineComplete && !isSecondTaglineShown) {
    // Use a faster typing speed for the second tagline
    const fasterTypingSpeed = 34; // Adjust this value as needed
    setTimeout(() => {
      setIsTyping(true);
      typeText("Eleve su propiedad en Buenos Aires a un activo lucrativo", 0, fasterTypingSpeed);
      setIsSecondTaglineShown(true);
    }, 2200);
  }
}, [isFirstTaglineComplete, isSecondTaglineShown]);



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (isFirstTaglineComplete) {
      setTimeout(() => {
        setTagline("Let's elevate your Buenos Aires property from a simple apartment to a lucrative asset.");
      }, 1000); // Wait for 1 second before changing tagline
    }
  }, [isFirstTaglineComplete]);

  const typingSpeed = 18; // Adjust based on your typing speed
  const taglineDuration = tagline.length * typingSpeed;
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
    setShowNotification(true);
    }, 20000); // Delay of 3 seconds
    
   
    return () => clearTimeout(timer);
    }, []);
    
    const handleCloseNotification = () => {
    setShowNotification(false);
    };

    const logoStyle = {
      marginTop: '-100px',
      width: '66%', // Adjust the width to 80%
      height: 'auto',
      filter: 'drop-shadow(0 0 10px black)',
      transition: 'all 0.9s ease-out', // Smooth transition for the logo
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginTop: '-400px',
      },
    };
useEffect(() => {
    // Start typing after 1.5 seconds
    const typingTimeout = setTimeout(() => {
      setStartTyping(true);
    }, 1500);

    // Show mission statement after tagline completes
    const missionStatementTimeout = setTimeout(() => {
    }, 1500 + taglineDuration); // Add tagline duration to the delay

    return () => {
      clearTimeout(typingTimeout);
      clearTimeout(missionStatementTimeout);
    };
  }, [taglineDuration]);
  const [open, setOpen] = useState(true); // Modal state

  const handleClose = () => {
  setOpen(false);
  };
  useEffect(() => {
    // Animate logo to fade in from the top
    if (logoRef.current) {
      gsap.fromTo(
        logoRef.current,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 1.5, ease: 'power3.out' }
      );
    }

    // Start typing after 1.5 seconds
    const typingTimeout = setTimeout(() => {
      setStartTyping(true);
    }, 1500);

    return () => clearTimeout(typingTimeout);
  }, []);
// Adjust the 'Learn More' button style to use absolute positioning
const LearnMoreButton = styled(motion.div)({
  position: 'absolute',
  bottom: '24%', // Adjust this value as needed
alignSelf: 'center',
  transform: 'translateX(-50%)',
  [theme.breakpoints.down('sm')]: {
    bottom: '27%'
  },
  // Other styles...
});

  useEffect(() => {
    const handleScroll = () => {
      // Logic to handle scroll events
      // Determine the current scroll position and update state accordingly
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  return (
    <div>
      <ScrollProgressBar height="5px" bgColor="#5cb85c" duration="1" />
      <StyledParallax
        blur={2.5}
        bgImage={currentHeroImage}
        bgImageAlt="Background Image"
        strength={200}
        style={{ backgroundPosition: 'center 100%', paddingBottom: '30px' }}
      >
        <Background />
        <Banner>
          <Container maxWidth="sm">
            <img 
              ref={logoRef} 
              src={logoImage} 
              alt="Logo" 
              style={logoStyle}
            />
          </Container>
          <TaglineContainer>
          {typewriterText}
        </TaglineContainer>
        {isSecondTaglineShown && !isTyping && (
          <LearnMoreButton
          variants={buttonVariants}
          initial="hidden"
          animate="visible"
        >
          <CustomButton onClick={scrollToMissionStatement}>
          Más Información
          </CustomButton>
        </LearnMoreButton>

        )}
      </Banner>
      </StyledParallax>
      <Container maxWidth="md" sx={{ my: 5, px: { xs: 0, sm: 3 } }}> {/* Remove horizontal padding on mobile */}
       {/* Mission Statement Section */}
       <MissionStatementSection ref={missionStatementRef}>
  <Container maxWidth="md" >
<Typography variant="h6" gutterBottom marginBottom={'20px'}>
  Ya estés lejos o justo al lado, proporcionamos soluciones de gestión de alquileres a medida para satisfacer tus necesidades.
</Typography>
<Typography variant="body1" paragraph>
Podés eligir nuestro <b>Paquete de Gestión Completa</b> para un enfoque sin preocupaciones, asegurando tranquilidad y máxima rentabilidad. <b>Recibe pagos mensuales en Airbnb, efectivo, PayPal, bitcoin, USDT y más.</b>
</Typography>
<Typography variant="body1" gutterBottom>
También contamos con la opción <b>AceleraRenta</b>, donde te ayudamos a transitar sin esfuerzo de una <b>propiedad recién construida o comprada a un activo generador de ingresos.</b>
</Typography>
<Typography variant="body1" marginTop={'30px'} paragraph>
  Explora nuestros servicios a continuación para encontrar el ajuste perfecto para tu propiedad.
</Typography>
    <Typography variant="h5" gutterBottom>
    </Typography>
    <Typography variant="body1" paragraph>
    </Typography>
  </Container>
</MissionStatementSection>
  <Typography variant="h4" align="center" gutterBottom>Nuestros Servicios</Typography>
  <Grid container spacing={{ xs: 0, sm: 3 }}>
  {serviceList.map((service, index) => (
    <Grid item xs={12} key={index}>
      <ServiceCard
        service={service}
        packageContent={packageContent}
        onGetInTouchClick={handleGetInTouchClick}
        contactRef={contactRef} // Pass contactRef as a prop
      />
    </Grid>
  ))}
</Grid>
</Container>
<BioSection />

<WhatsAppButtonStyle aria-label="WhatsApp" href={whatsappUrl} target="_blank">
        <WhatsAppIcon />
      </WhatsAppButtonStyle>
      <ContactSection onRef={contactRef} />
      <Box ref={ref} height="1px" width="100%" />
   {/* Notification Box */}
<NotificationBox
  initial="hidden"
  animate={showNotification ? "visible" : "hidden"}
  exit="exit"
  variants={notificationVariants}
>
  {!showDetails && (
    <>
      <Typography color="black" textAlign="center" variant="h8">
        Sos un agente inmobiliario o desarrollador, o conocés a alguien que quiera alquilar?
      </Typography>
      <StyledButton variant="contained" onClick={handleYesClick}>
        Si
      </StyledButton>
    </>
  )}
  {showDetails && (
    <>
      <Typography color="black" textAlign="center" variant="h7" sx={{ mb: 0 }}>
        Ofrecemos una generosa comisión por referencias por cada unidad que nos ayudes a registrar.
      </Typography>
      <StyledButton variant="contained" href={mailToLink}>
        Referir
      </StyledButton>
    </>
  )}
  <IconButton onClick={handleCloseNotification} color="inherit" sx={{ position: 'fixed', top: 10, right: 8, marginTop: '-12px', marginRight: '-10px', opacity: '0.4' }}>
<CloseIcon />
</IconButton>
</NotificationBox>
</div>
  );
};

export default Home;